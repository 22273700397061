<template>
  <div class="sign">
    <div ref="pdf" class="sign-pdf" @click.self="show = false">
      <pdf ref="pdfs" v-for="i in numPages" :src="tmpDocUrl" :key="i" :page="i"></pdf>
      <div
        v-for="(item, i) in posiInfo"
        class="sign-pdf-btn"
        :class="ids && item.id == ids ? 'borders' : ''"
        :style="{ 'left': `${item.left}rem`, 'top': `${item.top}rem`, 'width':`${item.width}rem`, 'height':`${item.height}rem`}"
        :key="item.id"
        @click="openSign(item, i)"
      >
        <span v-if="!item.img" class="sign-pdf-btn-span">{{sealStaus(item.sealType)}}</span>
        <img v-if="item.img" class="sign-pdf-btn-img" :src="item.img" />
      </div>
    </div>
    <div class="sign-sign"></div>
    <div class="sign-btn">
      <div class="sign-btn-pos align-center" @click="findPosition"><span class="sign-btn-pos-num">{{ arrNum }}</span>查看签约位置</div>
      <div class="sign-btn-enclosure align-center" @click="enclosure"><span class="sign-btn-pos-num">{{ documentNum }}</span>查看附件</div>
      <div v-if="arrNum == 0" class="sign-btn-sign align-center"  @click="submit">提交签署</div>
      <div v-else class="sign-btn-sign1 align-center"  @click="submit">提交签署</div>
    </div>
    <van-popup v-model="show" position="bottom" :overlay="true">
      <div class="sign-popup">
        <div class="sign-popup-title">
          <div class="sign-popup-title-text">{{currSelectPosiInfo.sealType === 9 ? '签署日期' : currSelectPosiInfo.sealType === 1 ? '选择印章' : '个人签章'}}</div>
          <img
            v-if="currSelectPosiInfo.sealType === 2"
            @click="addSign"
            class="sign-popup-title-img"
            src="@/assets/add.png"
            alt=""
          />
        </div>
        <div class="sign-popup-con">
          <template v-if="signList.length">
            <div :class="{ 'sign-popup-con-o': true, 'sign-popup-con-o-select': (currSelectPosiInfo.sealType === 1 && gZ === i) || (currSelectPosiInfo.sealType === 2 && pers === i) || (currSelectPosiInfo.sealType === 9 && timeIndex === i)}" v-for="(item, i) in signList" :key="item.id">
              <div class="sign-popup-con-gz-title" v-if="currSelectPosiInfo.sealType === 1">{{item.sealName}}</div>
              <div class="sign-popup-con-i-box" @click="selectSign(i, item)">
                <!-- <img v-if="item.previewPath" class="sign-popup-con-i" :src='item.previewPath' :style="{ width: `${item.width}px`, height: `${item.height}px` }" alt=""> -->
                <img v-if="item.previewPath" class="sign-popup-con-i" :src='item.previewPath' alt="">
                <div v-else class="sign-popup-con-div" alt="">{{ item.time }}</div>
              </div>
              <img v-if="item.previewPath && currSelectPosiInfo.sealType !== 1" @click.self="del(item)" class="sign-popup-con-d" src="@/assets/del.png" alt="">
            </div>
          </template>
          <template v-else>
            <div class="sign-popup-con-title">
              <div class="sign-popup-con-title-text">{{currSelectPosiInfo.sealType === 1 ? '暂无印章，请去添加' : '暂无签名，请去添加'}}</div>
            </div>
          </template>
          <!-- <div v-if="currSelectPosiInfo.sealType === 9" class="sign-popup-con-div-btn" @click="changeTime">确定</div> -->
        </div>
      </div>
    </van-popup>
    <van-popup v-model="fileShow" position="bottom" :overlay="true">
      <div class="sign-popup">
        <div class="sign-popup-title">
          <div class="sign-popup-title-text">查看附件</div>
        </div>
        <div class="sign-popup-con">
          <template v-if="fileNumber.length">
            <div :class="{ 'sign-popup-con-o': true, 'sign-popup-con-o-select': (showIndex == i )}" v-for="(item, i) in fileNumber" :key="item.id">
              <div class="sign-popup-con-i-box" @click="checkFile(documentNum, i)">
                <div class="sign-popup-con-file" alt="">{{ item.fileDisplayName }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </van-popup>
    <Pop :hasRealNameSendCode="hasRealNameSendCode" @close="closeP" :round="true" position="bottom">
      <div class="sign-send">
        <div class="sign-send-title">
          <div class="sign-send-title-text">验证码校验</div>
          <img class="sign-send-title-img" @click="closeIcon" src="@/assets/close.png" alt="">
        </div>
        <div class="sign-send-iphone">
          验证码已发送至{{ `${userInfo.contact&&userInfo.contact.substring(0, 3)}****${userInfo.contact&&userInfo.contact.substring(userInfo.contact.length -4)}` }}
        </div>
        <div class="sign-send-codeBox" @click="getFocus">
          <div class="sign-send-codeBox-box">
            <div class="sign-send-codeBox-i" v-for="(item, index) in codeArray" :key="index">{{ item }}</div>
          </div>
          <input
            class="sign-send-s"
            ref='inputRef'
            type="number"
            pattern="[0-9]*"
            v-model="inputValue"
            @input='hanldeInputCodeChange'
            onkeyup="this.value=this.value.replace(/[^0-9-]+/,'')"
            :maxlength="6"
          />
        </div>
        <div class="sign-send-time">
          <div v-if="sendTime !== 60" class="sign-send-time-t">{{ sendTime }}秒后重新获取</div>
          <div v-if="sendTime === 60" class="sign-send-time-c" @click="getMessCode">重新获取</div>
        </div>

        <div class="sign-send-btn" @click="sendF">
          确定
        </div>
      </div>
    </Pop>

    <PopList :showModel.sync="popBox.showModel" :popObj.sync="popBox.popObj" :list.sync="popBox.list" :fn="popBox.callbackFn"  :editFormData="popBox.editFormData"></PopList>

  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import { signatureDetails, signPositionBySignature, personSealList, deleteSeal, queryDocFilesByDocumentNosAndTenantId, textToImg, sendAuthCodeByMobile, checkSignSms, signature,selectSealList, hasRealnameExam,
hasAuthority, applyAuthority } from "@/util/api";
import { mapState, mapMutations } from "vuex";
import { changeFormData, ossUrl } from "@/util/tool";
import PopList from '@/views/components/popList/index.vue'
import pdf from "vue-pdf";
// import Esign from './esign.vue'
import Pop from './pop.vue'
export default {
  name: 'sign',
  components: {
    pdf,
    Pop,
    PopList
    // Esign
  },
  data() {
    return {
      documentNum: 0, // 附件个数
      fileNumber: [], // 附件
      fileShow: false, //多个附件
      showIndex: -1, //多个附件
      tmpDocUrl: "",
      posiInfo: [],
      show: false,
      signList: [],
      curr: null,
      currSelectPosiInfo: {},
      numPages: 1,
      allSignData: [],
      posiInfoIndex: 0,
      iframe_src: '',
      temShow: false,
      temporary: [],
      tenantSealId: '',
      timeIndex: null,
      timeIsImg: false,
      gZ: '',
      sealAuthId: '',
      pers: '',
      hasRealNameSendCode: false,
      codeArray: [],
      
      sendBtn: "发送验证码",
      sendTime: 59,
      inputValue: '',
      signatureParams: {},
      prePosition: '',
      timer: null,
      ids: null,
      isScroll: false,
      popBox: { // 选择签署方式弹框
        showModel: false,
        type: 1,
        popObj:{
          labelName: 'name',
          valueName: 'id',
          list: []
        },
        list: [],
        callbackFn: () => {},
        editFormData: {}
      },
      companyId: '',
      defaultPersonSeal: {},//默认个性签名
    };
  },
  computed: {
    ...mapState(["signatureId", 'linkCodeInfo', 'userInfo', 'sign', 'stateallSignData', 'stateposiInfo', 'preP','signPartyType','scrollTop']),
    ...mapMutations(['setSignatureDetails','setSignPartyType']),
    arrNum() {
      return this.allSignData.reduce((pre, curr) => {
        if (curr === null) {
          pre += 1
        }
        return pre
       }, 0)
    }
  },
  watch: {
    sign(c) {
      console.log(c);
        this.hide(true)
    },
    $route(to,from){
      console.log(to,from);
      // debugger
      if((to.path === '/sign' && from.path === '/home') || (to.path === '/sign' && from.path === '/sign')){
        this.signDetail()
      }else{
        this.isScroll = true
        this.setPosiInfo([])
        if(this.scrollTop){
          const { onceHeight, page } = this.scrollTop
          this.$refs.pdf.scrollTo(0, onceHeight*(page-1))
          this.prePosition = onceHeight*(page-1) || 0
          this.setPreP(onceHeight*(page-1) || 0)
        }
      }
    }
  },
  methods: {
    ...mapMutations(['signOver', 'setSignatureParam', 'setAllSignData', 'setPosiInfo', 'setPreP','setScrollTop']),
    sealStaus(status) {
      let value = ''
        switch(status) {
          case 1:
            value = '公章'
            break;
          case 2:
            value = '签名'
            break;
          case 9:
            value = '日期'
            break;
          default:
            value = ''
        }
      return value
    },
    moveHandle(){},
    addSign() {
      this.show = false
      // this.temShow = true
      this.$nextTick(() => {
        this.$router.push({ path: '/esign' })
      })
    },
    hide() {
      this.getPersonSealList(true)
    },
    getPersonSealList(isNewAdd) {
      personSealList().then(res => {
        this.signList = res
        this.defaultPersonSeal = this.signList.find(v=>v.defaultFlag == 1)
        if (isNewAdd) {
          // const i = res.length - 1
          this.selectSign(0, res[0])
        }
      })
    },
    openSign(item, i) {
      console.log(item)
      // 当前位置信息
      this.$store.commit('setScrollTop',item)
      this.currSelectPosiInfo = item
      this.posiInfoIndex = i
      if (item.sealType === 2) {
       this.getPersonSealList()
      } else if (item.sealType === 1) {
        this.getTenantSeal()
      } else {
        console.log(this.posiInfo,'posiInfo');
        this.posiInfo.map(item =>{
          console.log(item);
          if(item.config&&item.config.dateFormat !== ''){
            this.signList = [
              {
                time: new Date().format(`${item.config.dateFormat}`)
              },
            ]
          }else{
            this.signList = [
              {
                time: new Date().format('yyyy-MM-dd')
              },
            ]
          }
        })
      }
      this.$nextTick(() => {
        this.show = true
      })
    },
    async selectSign (i, item) {
      this.curr = i
      const { sealType } = this.currSelectPosiInfo
      if (sealType === 1) {
        // this.gZ = i
        this.getHasAuthority(i, item)
      } else if (sealType === 2) {
        this.pers = i
        this.setAllSignDataVal(item)
      } else {
        this.timeIndex = i
        textToImg({ text: this.signList[this.timeIndex].time }).then(res => {
          res = { ...res, previewPath: res.imgUrl, sealType: 9 }
          this.timeIsImg = false
          this.selectSign1(this.timeIndex, res)
        })
        this.setAllSignDataVal(item)
      }
      console.log(this.currSelectPosiInfo.sealType);
      console.log(this.gZ);
      // if (!this.timeIsImg && item.time) return
      // this.posiInfo[this.posiInfoIndex].img = item.previewPath

      // this.$set(this.allSignData, this.posiInfoIndex, {
      //   positionId: this.currSelectPosiInfo.id,
      //   sealId: item.sealType === 9 ? '' : item.id,
      //   sealImgUrl:  item.sealType === 9 ? '' : item.previewPath,
      //   signDateImgUrl:  item.sealType === 9 ? item.previewPath : '',
      //   signDate: item.sealType === 9 ? new Date().format('yyyy-MM-dd') : '',
      //   sealAuthId: this.posiInfo[this.posiInfoIndex].sealType === 1 ? this.sealAuthId : '',
      //   ...this.posiInfo[this.posiInfoIndex]
      // })
      // this.$nextTick(() => {
      //   this.setAllSignData(this.allSignData)
      //   this.setPosiInfo(this.posiInfo)
      // })
      // this.$set(this.temporary, this.posiInfoIndex, {
      //   positionId: this.currSelectPosiInfo.id,
      //   sealId: item.id,
      //   sealImgUrl: item.previewPath
      // })

      // this.show = false
      // this.curr = null
    },
    setAllSignDataVal(item) {
      if (!this.timeIsImg && item.time) return
      this.posiInfo[this.posiInfoIndex].img = item.previewPath

      this.$set(this.allSignData, this.posiInfoIndex, {
        positionId: this.currSelectPosiInfo.id,
        sealId: item.sealType === 9 ? '' : item.id,
        sealImgUrl:  item.sealType === 9 ? '' : item.previewPath,
        signDateImgUrl:  item.sealType === 9 ? item.previewPath : '',
        signDate: item.sealType === 9 ? new Date().format('yyyy-MM-dd') : '',
        sealAuthId: this.posiInfo[this.posiInfoIndex].sealType === 1 ? this.sealAuthId : '',
        ...this.posiInfo[this.posiInfoIndex]
      })
      this.$nextTick(() => {
        this.setAllSignData(this.allSignData)
        this.setPosiInfo(this.posiInfo)
      })
      this.$set(this.temporary, this.posiInfoIndex, {
        positionId: this.currSelectPosiInfo.id,
        sealId: item.id,
        sealImgUrl: item.previewPath
      })

      this.show = false
      this.curr = null
    },
    selectSign1 (i, item) {
      this.curr = i
      const { sealType } = this.currSelectPosiInfo
      if (sealType === 1) {
        this.gZ = i
      } else if (sealType === 2) {
        this.pers = i
      } else {
        this.timeIndex = i
      }
      console.log(this.currSelectPosiInfo.sealType);
      console.log(this.gZ);
      if (!this.timeIsImg && item.time) return
      this.posiInfo[this.posiInfoIndex].img = item.previewPath

      this.$set(this.allSignData, this.posiInfoIndex, {
        positionId: this.currSelectPosiInfo.id,
        sealId: item.sealType === 9 ? '' : item.id,
        sealImgUrl:  item.sealType === 9 ? '' : item.previewPath,
        signDateImgUrl:  item.sealType === 9 ? item.previewPath : '',
        signDate: item.sealType === 9 ? new Date().format('yyyy-MM-dd') : '',
        ...this.posiInfo[this.posiInfoIndex]
      })
      this.$nextTick(() => {
        this.setAllSignData(this.allSignData)
        this.setPosiInfo(this.posiInfo)
      })
      this.setAllSignData()
      this.$set(this.temporary, this.posiInfoIndex, {
        positionId: this.currSelectPosiInfo.id,
        sealId: item.id,
        sealImgUrl: item.previewPath
      })

      this.show = false
      this.curr = null
    },
    del (item) {
      deleteSeal({ processId: this.linkCodeInfo.processId, sealId: item.id }).then(() => {
        this.getPersonSealList()
      })
    },
    submit() {
      console.log('this.userInfo', this.userInfo);
      if (!(this.allSignData.length === this.posiInfo.length)) {
        this.$toast.fail("请先输入签约");
        return ;
      }
      //判断签约位置是否有误
      let arrNum = this.allSignData.reduce((pre, curr) => {
           if (curr === null) {
              pre += 1
           }
           return pre
      }, 0)
      if (arrNum != 0 || this.allSignData.length !== this.posiInfo.length) {
        this.$toast.fail("签约位置填写不全");
        return
      }
      //判断是否实名认证了
      if (!this.userInfo.realnameId) {//未认证
        hasRealnameExam({}).then((res)=>{//判断是否有审核中的认证
             // const { data, code } = res
             console.log('获取数据', res)
             if(res){
                 this.$toast.fail("认证正在审核中,请耐心等待");
             }else{
                this.showPopList() // 展示选择方式
             }
         })
      } else { //已实名认证
        console.log('this.allSignData', this.allSignData);
        const params = {
            actionType: 1,
            processSigns: [{ details: this.allSignData, processId: this.allSignData[0].processId }],
            signatureId: this.allSignData[0].signatureId
        }
        this.setSignatureParam(params)
        // 已经实名认证的 需要发送验证码//发送意愿短信
        this.hasRealNameSendCode = true
        this.signatureParams = params
        this.getSendInfo()
        if(this.sendTime == 59) {
            this.getMessCode()
        }
      }
    },
    peopleM(){
      if (this.allSignData.length === this.posiInfo.length) {
        console.log('this.allSignData', this.allSignData);
        const params = {
          actionType: 1,
          processSigns: [{ details: this.allSignData, processId: this.allSignData[0].processId }],
          signatureId: this.allSignData[0].signatureId
        }
        this.setSignatureParam(params)
        if (!this.userInfo.realnameId) {
          this.$router.push({ path: '/realName' })
          return
        }
        // 已经实名认证的 需要发送验证码
        this.hasRealNameSendCode = true
        this.inputValue = ''
        this.codeArray = []
        this.signatureParams = params
        this.getSendInfo()
        if(this.sendTime == 59) {
          this.getMessCode()
        }
        // signature(params).then((res) => {
        //   console.log(res);
        //   this.$router.push({ path: '/success' })
        // })
      } else {
        this.$toast.fail("请输入先签约");
      }
    },
    checkFile (val, index) {
      if (val == 1) {
        let fileUrl = this.fileNumber[0].fileUrl
        let suffix = fileUrl.split('?')[0].split('.')[fileUrl.split('?')[0].split('.').length - 1]
        if (suffix == 'pdf') {
        this.$router.push({ path: '/pdf', query: { fileUrl }})
        } else {
          ossUrl(this.fileNumber[0].fileUrl)
        }
      } else {
        this.showIndex = index
        let fileUrl = this.fileNumber[index].fileUrl
        let suffix = fileUrl.split('?')[0].split('.')[fileUrl.split('?')[0].split('.').length - 1]
        if (suffix == 'pdf') {
          this.$router.push({ path: '/pdf', query: { fileUrl }})
        } else {
          ossUrl(this.fileNumber[index].fileUrl)
        }
      }
    },
    enclosure (val) {
      const { tenantId } = this.userInfo
      let documentNos = this.attachmentDocNo
      if (documentNos != undefined) {
        if (val == 1) {
          documentNos = Array.isArray(documentNos) ? documentNos : [documentNos]
          queryDocFilesByDocumentNosAndTenantId({ documentNos, tenantId }).then((res) => {
            this.fileNumber = res[documentNos]
            this.documentNum = res[documentNos].length
          })
        } else {
          if (this.documentNum == 0) {
            return false
          } else if (this.documentNum == 1) {
            let fileUrl = this.fileNumber[0].fileUrl
            let suffix = fileUrl.split('?')[0].split('.')[fileUrl.split('?')[0].split('.').length - 1]
            if (suffix == 'pdf') {
            this.$router.push({ path: '/pdf', query: { fileUrl }})
            } else {
              ossUrl(this.fileNumber[0].fileUrl)
            }
          } else{
            this.fileShow = true
          }
        }
      } else {
        this.documentNum = 0
      }
    },
    currPdfH(i) {
      if (i === 1) return 0
      let top = 0
      while (i > 1) {
        top += this.$refs.pdfs[i-1].$el.offsetHeight
        i--
      }
      return top
    },
    findPosition() {
      console.log(this.allSignData,'allSignData');
      // const allSignDataIndex = this.allSignData.findIndex((v) =>  v === null)
      // if (allSignDataIndex === -1) {
      //   this.$toast.success('已经签完所有位置')
      //   return
      // }
      console.log(this.posiInfo,'posiInfo');
      const index = this.temporary.findIndex((v) =>  v === null)
      if (this.arrNum && index === -1) {
        this.temporary = [...this.allSignData]
        this.findPosition()
        return
      }
      console.log(index,'index');
      console.log(this.arrNum,'this.arrNum');
      if(index !== -1){
        const { id, onceHeight, page } = this.posiInfo[index]
        this.$refs.pdf.scrollTo(0, onceHeight*(page-1))
        this.prePosition = onceHeight*(page-1) || 0
        this.setPreP(onceHeight*(page-1) || 0)
        this.$set(this.temporary, index, id)
        this.ids = id
      }else{
        this.temporary = new Array(this.allSignData.length).fill(null)
        this.findPosition()
      }
    },
    getTenantSeal() {
      this.signList = []
      // if(this.signPartyType === 1){
      //   sealDetail({ tenantSealId: this.tenantSealId }).then(res => {
      //     res = Array.isArray(res) ? res : [res]
      //     this.signList = res
      //   })
      // }else{
        selectSealList({companyId:this.companyId, signatureId: this.$route.query.signatureId}).then(res =>{
          res = Array.isArray(res) ? res : [res]
          res.map(item =>{
            // if(item.sealUse == 1){
              this.signList.push(item)
            // }
          })
        })
      // }
      
    },
    changeTime() {
      // textToImg({ text: this.signList[this.timeIndex].time }).then(res => {
      //   console.log(res);
      //   res = { ...res, previewPath: res.imgUrl, sealType: 9 }
      //   this.timeIsImg = false
      //   this.selectSign(this.timeIndex, res)
      // })
    },
    getSendInfo() {
       const defaultCodeArray = new Array(6).fill('')
       this.codeArray = defaultCodeArray
    },
    getMessCode() {
      // if (this.sendTime < 60) {
      //   return false
      // }
      sendAuthCodeByMobile({ captcha: '', mobilePhone: this.userInfo.contact }).then((res) => {
        // console.log();
        this.$refs.inputRef.focus()
        console.log('res', res);
        this.timer = setInterval(() => {
          this.sendTime -= 1;
          if (this.sendTime === 0) {
            this.sendTime = 60;
            clearInterval(this.timer);
          }
        }, 1000);
      })
    },
    hanldeInputCodeChange() {
      if (this.inputValue.length >= 6) {
        this.$refs.inputRef.blur()
        this.inputValue = this.inputValue.substring(0, 6)
      }
      this.codeArray = this.codeArray.map((v, i) => {
        return this.inputValue[i]
      })
    },
    getFocus() {
      this.$refs.inputRef.focus()
    },
    sendF() {
      if (!this.inputValue || this.inputValue.length < 6) {
        this.$toast.fail("请输入正确的验证码");
        return
      }
      checkSignSms(changeFormData({ authCode: this.inputValue })).then(code => {
        if(code != 0){
          clearInterval(this.timer);
          this.sendTime = 60
          return
        }
        let signatureParams = {
          ...this.signatureParams,
          authCode:this.inputValue,
        }
        signature(signatureParams).then((res) => {
          console.log(res);
          this.$router.push({ path: '/success', query:{finalDocNo: res.finalDocNo} })
        })
      })
    },
    closeP(val) {
      this.hasRealNameSendCode = val
    },
    closeIcon() {
      this.inputValue = ''
      this.hasRealNameSendCode = false
    },
    renderPdf(tmpDocUrl, params){
      this.enclosure(1)
      var loadingTask = pdf.createLoadingTask(tmpDocUrl, {withCredentials: false});
      // var loadingTask = pdf.createLoadingTask('https://jugui-em.oss-cn-beijing.aliyuncs.com/doc/1655986779537/1655986779537_vfuIvarEA0.pdf?Expires=1658579407&OSSAccessKeyId=LTAINu9DSTputpOG&Signature=1N7pwxpkQFbG%2BBKYQB92RZNcric%3D', {withCredentials: false});
      console.log(loadingTask)
      this.tmpDocUrl = loadingTask
      this.tmpDocUrl.promise.then(pdf => {
        this.numPages = pdf.numPages;

        signPositionBySignature(params).then((res) => {
          this.posiInfo = res;
          this.allSignData = new Array(res.length).fill(null)
          this.temporary = new Array(res.length).fill(null)
          this.$nextTick(() => {
            const onceHeight = this.$refs.pdfs[0].$el.offsetHeight
            console.log(onceHeight,'122121', this.$refs.pdfs[0].$el.offsetWidth)
            res = res.map(v => {
              return {
                ...v,
                top: (v.height*375/ v.width* (v.page - 1) + v.top*375/ v.width)/37.5,
                left: (v.left*375/ v.width)/37.5,
                width: v.imgScaleX*v.imgWidth*375/ v.width/37.5,
                height: v.imgScaleY*v.imgHeight*375/ v.width/37.5,
                onceHeight: onceHeight
              }
            })
            console.log(res)
            this.posiInfo = res;
            // this.setAllSignData(this.allSignData)
            // this.setPosiInfo(this.posiInfo)
            if(this.defaultPersonSeal) {
              this.posiInfo.map((item, index)=>{
                if(item.sealType == 2) {
                  this.$set(this.allSignData, index, {
                    positionId: item.id,
                    sealId: this.defaultPersonSeal.id,
                    sealImgUrl:  this.defaultPersonSeal.previewPath,
                    ...this.posiInfo[index]
                  })
                  item.img = this.defaultPersonSeal.previewPath
                }
              })
              this.setAllSignData(this.allSignData)
              this.setPosiInfo(this.posiInfo)
            }
            
            if (this.stateallSignData && this.stateposiInfo && this.stateposiInfo.length > 0 &&  this.stateposiInfo[0].id == this.posiInfo[0].id) {
              this.posiInfo = this.stateposiInfo
              this.allSignData = this.stateallSignData
              
              this.$refs.pdf.scrollTo(0, this.preP)
            }
          })
        });
      });
    },
    signDetail () {
      let signatureId = this.$route.query.signatureId
      // let partyType = ''
      const params = changeFormData({
        signatureId
      })
      signatureDetails(params).then((res) => {
        this.$store.commit('setSignatureDetails',res)

        const { attachmentDocNo, tmpDocUrl, tenantSealId } = res
        this.tenantSealId = tenantSealId
        this.attachmentDocNo = attachmentDocNo
        let process = res.process || []
        process.map(item => {
          if(item.signerContact	 === this.userInfo.contact) {
            // partyType = item.partyType
            this.companyId = item.companyId
            this.$store.commit('setSignPartyType',item.signPartyType)
          }
        })
        this.renderPdf(tmpDocUrl, params)
        // if( partyType == 1 && res.tenantEnterpriseType == 1){
        //   getCompanyRealnameInfo(this.companyId).then(res =>{
        //   if(res.realnameStatus == 0 || res.realnameStatus == -1){
        //     this.$dialog.alert({
        //       title:'提示',
        //       message:'该企业未实名认证，请先完成企业实名认证',
        //       confirmButtonText: '确认',
        //       cancelButtonText: '取消',
        //       showCancelButton: true
        //     }).then(()=>{
        //       this.$router.push({path:'/enterpriseCertification',query:{companyId:this.companyId}})
        //     }).catch(()=>{
        //       this.$toast('已取消')
        //     })
        //   }else if(res.realnameStatus == 2){
        //     this.$toast.fail('该企业正在审核中，请耐心等待')
        //     return
        //   } else {
        //     this.renderPdf(tmpDocUrl, params)
        //   }
        // })
        // } else {
        //   this.renderPdf(tmpDocUrl, params)
        // }
      });
    },
    showPopList() {
      this.popBox.showModel = true
        this.popBox.list = [
          {id: 1, name: '手机号验证'},
          {id: 2, name: '人脸识别认证'},
        ]
      this.popBox.callbackFn = (val) => { // 回调处理
      if (val.id == 1){
        this.peopleM()
      } else if(val.id == 2){
        let ttobj = {
          processSigns: [{ details: this.allSignData, processId: this.linkCodeInfo.processId }],
          allSignData: this.allSignData,
          signatureId: this.$route.query.signatureId
        }
        this.$router.push({ path: '/videoCertification', query:{ ttobj: Base64.encode(JSON.stringify(ttobj))} }) //  人脸实名认证
      }
        // 重新载入
        this.popBox.showModel = false
        this.popBox.popObj.list = []
        this.popBox.editFormData = {}
      }
    },
    getHasAuthority(i, item) {
      let param = {
        companyId: this.companyId,
        signatureId: this.$route.query.signatureId,
        sealId: item.id
      }

      hasAuthority(param).then(res =>{
        if(res.authorityStatus == 0){
          this.$dialog.alert({
            title:'提示',
            message:`您无签署权限，请向${res.sealAdmin&&res.sealAdmin.fullName?res.sealAdmin.fullName.substring(0,1) + new Array(res.sealAdmin.fullName.length).join('*'):'企业管理员'}申请签署权限!`,
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            showCancelButton: true
          }).then(()=>{
            applyAuthority(param).then(res =>{
              console.log(res);
              this.$toast('已发送申请,请稍后再试!')
            })
          }).catch(()=>{
            this.$toast('已取消')
          })
        }else if(res.authorityStatus == 1){
          this.gZ = i
          this.sealAuthId = res&&res.sealAuthId?res.sealAuthId:''
          this.setAllSignDataVal(item)
        }else if(res.authorityStatus == 2){
          this.$dialog.alert({
            title:'提示',
            message:'授权时间已过期,请重新申请签署权限!',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            showCancelButton: true
          }).then(()=>{
            applyAuthority(param).then(res =>{
              console.log(res);
              this.$toast('已发送申请,请稍后再试!')
            })
          }).catch(()=>{
            this.$toast('已取消')
          })
        }else if(res.authorityStatus == -1){
          this.$dialog.alert({
            title:'提示',
            message:'印章授权没有管理员 需要企业实名认证!',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            showCancelButton: true
          }).then(()=>{
            // applyAuthority(param).then(res =>{
            //   console.log(res);
            //   this.$toast('已发送申请,请稍后再试!')
            // })
            this.$router.push({path:'/enterpriseCertification',query:{companyId:this.companyId}})
          }).catch(()=>{
            this.$toast('已取消')
          })
        }
      })
    }
  },
  activated() {
    this.$refs.pdf&&this.$refs.pdf.scrollTo(0, this.prePosition)
  },
  async mounted() {
    if(!this.isScroll){
      this.$refs.pdf.scrollTo(0,0)
      this.prePosition = 0
      this.setPreP(0)
    }
    await this.getPersonSealList()
    await this.signDetail()
    // this.getTenantSeal()
  },
};
</script>

<style lang="less" scoped>
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-pdf {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    &-btn {
      position: absolute;
      width: 83px;
      height: 38px;
      line-height: 38px;
      background: rgba(0, 103, 225, 0.4);
      // border: 2px dashed #0067e1;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      z-index: 11;
      justify-content: center;
      align-items: center;
      &-span {
        white-space: nowrap;
        font-size: 12px;
      }
      &-img {
        // height: 38px;
        // max-width: 80px;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-sign {
    width: 100%;
    height: 66px;
  }
  &-btn {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255);
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.04);

    position: fixed;
    left: 0;
    bottom: 0;
    opacity: 1;
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    &-pos {
      width: 120px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      opacity: 1;
      text-align: center;
      position: relative;
      &-num {
        width: 16px;
        height: 16px;
        background: #E34D59;
        border-radius: 50%;
        display: inline-block;

        color: #FFFFFF;
        line-height: 16px;
        text-align: center;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;

        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &-enclosure {
      width: 88px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      opacity: 1;
      text-align: center;
      position: relative;
    }
    &-sign {
      width: 108px;
      height: 44px;
      background: rgba(0, 103, 225);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #ffffff;
      text-align: center;
    }
    &-sign1 {
      width: 108px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      text-align: center;
    }
  }
  &-popup {
    padding: 20px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 16px;
      &-text {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586c;
        opacity: 1;
      }
      &-img {
        width: 28px;
        height: 18px;
      }
    }
    &-con {
      flex: 1;
      // display: flex;
      // flex-direction: column;
      overflow: auto;
      &-o {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 18px 0 18px;
        &-select {
          background: #F6F7F8;
        }
      }
      &-d {
        width: 20px;
        height: 20px;
      }
      &-gz-title{
        font-size: 16px;
        width: 30%;
      }
      &-i {
        height: 60px;
        margin: 0 auto;
        &-box {
          flex: 1;
          display: flex;
        }
      }
      &-title {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #30586C;
          opacity: 1;
          text-align: center;
        }
      }
      &-div {
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 48px;
        color: #464646;
        opacity: 1;
        text-align: center;
        &-btn {
          width: 100%;
          height: 44px;
          background: #0067E1;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 44px;
          color: #FFFFFF;
          opacity: 1;
          border-radius: 30px;
          text-align: center;
        }
      }
      &-file {
        width: 300px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 48px;
        color: #464646;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  &-send {
    width: 320px;
    height: 268px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
      box-sizing: border-box;
    &-s {
      box-sizing: content-box;
      // visibility: hidden;
      position: absolute;
      top: 5px;
      // opacity: 0;
      border: 0;
      letter-spacing: 30px;
      // z-index: 1;
      text-indent: 20px;
      height: 30px;
      width: 100%;
    }
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-text {
        height: 23px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586C;
        opacity: 1;
      }
      &-img {
        width: 20px;
        height: 20px;
      }
    }
    &-iphone {
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #30586C;
      margin-top: 8px;
    }
    &-codeBox {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 16px;
      height: 40px;
      position: relative;
      &-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        position: absolute;
        z-index: 1;
        background: #FFF;
      }
      &-i {
        width: 40px;
        height: 40px;
        background: #F6F7F8;
        opacity: 1;
        border-radius: 3px;
        text-align: center;
        line-height: 40px;
      }
    }
    &-time {
      flex: 1;
      box-sizing: border-box;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-t {
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8B96A6;
        opacity: 1;
      }
      &-c {
        height: 12px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0067E1;
        text-decoration: underline;
        opacity: 1;
        flex: 1;
        text-align: right;
      }

    }
    &-btn {
      width: 100%;
      height: 44px;
      background: #0067E1;
      opacity: 1;
      border-radius: 22px;

      margin-top: 46px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #FFFFFF;
      opacity: 1;

      text-align: center;
    }
  }
  /deep/ .van-popup {
    box-shadow: 0px -7px 15px rgba(65, 191, 232, 0.3);
    border-radius: 12px 12px 0px 0px;
  }
  .sign-popup-iframe {
    width: 90vw;
    height: 70vh;
    // overflow-x: ;
    display: flex;
  }
  .inlineFrameExample {
    flex: 1;
  }
  /deep/.img-area {
    img {
      width: 100%;
    }
  }
  &-esign {
    width: 100%;
    height: 100%;
  }
  .borders{
    border: 1px dashed #0067E1;
  }
}
</style>