<template>
  <div>
    <van-popup v-model="hasRealNameSendCode" @click-overlay="close" :round="round" position="center" :overlay="true">
      <slot></slot>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ['hasRealNameSendCode', 'position', 'round'],
  methods: {
    close() {
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .van-popup {
    box-shadow: 0px -7px 15px rgba(65, 191, 232, 0.3);
    border-radius: 12px !important;
  }
</style>